<template>
    <Page
        :title="$t('sreq.list.title')"
        :watch-for-scroll="true"
        :content-loading="loading"
        content-class="page-paddings pt-4"
        @reachedBottom="loadMore()"
    >
        <template v-slot:header-addition>
            <div class="page-paddings">
                <Tabs @changed="handleStatusUpdate">
                    <Tab
                        v-for="option of schemaOptions"
                        :key="option.key"
                        :title="option.value"
                    />
                </Tabs>
            </div>
        </template>

        <div class="page-title flex items-center justify-between">
            {{ selectedTabTitle }}
            <router-link
                :to="{ name: 'serviceRequests.create' }"
                class="btn btn-primary service-request-list__add-button"
            >
                <Icon
                    name="plus"
                    class="w-3 h-3 mr-2 text-white"
                />
                <span>NEW</span>
            </router-link>
        </div>

        <hr class="my-6" />

        <div
            v-if="status === STATUS_GROUP_RESIDENT_VALIDATION && items?.length && showAutocompleteInfobox && !loading"
            class="infobox flex text-md2 font-medium mb-4 p-4 relative"
        >
            <icon
                name="exclamation-circle"
                class="text-gray-400 mt-1 mr-4 w-3 h-3 flex-none"
            />
            <div class="text-gray-500">{{ $t('sreq.list.autocompletion_notice') }}</div>
            <div
                class="block w-5 h-5 bg-white text-gray-500 rounded-full p-0.5 absolute -top-2 -right-2"
                @click="closeAutocompleteInfobox"
            >
                <icon name="close" />
            </div>
        </div>

        <div class="list__header">
            <div
                v-for="column in columns"
                :key="column.name"
                class="list__cell"
            >
                {{ column.name }}
            </div>
        </div>
        <router-link
            v-for="item of items"
            :key="item.value.id"
            :to="{ name: 'serviceRequests.details', params: { requestId: item.value.id } }"
            class="list__row"
        >
            <div
                v-for="column in columns"
                :key="`${item.value.id}-${column.name}`"
                class="list__cell"
                :class="{ [column.rowClass]: column.rowClass }"
            >
                {{ typeof column.value === 'function' ? column.value(item.value) : item.value[column.value] }}
            </div>
        </router-link>

        <div
            v-if="!loading && items.length === 0"
            class="flex flex-col items-center justify-center w-full h-full"
        >
            <icon
                name="empty"
                class="requests__empty-icon"
            />
            <div class="text-sm text-gray-400">{{ $t('sreq.list.is_empty') }}</div>
        </div>
    </Page>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { dateFormatter } from '@/utils/Date';
import Page from '@/components/ui/Page';
import AuthMixin from '@/mixins/AuthMixin';
import InfiniteScrollMixin from '@/mixins/InfiniteScrollMixin';
import { STATUS_GROUP_OPENED, STATUS_GROUP_RESIDENT_VALIDATION, STATUS_GROUP_RESOLVED, CATEGORY_CUSTOM, SCHEMA_STATUS } from './constants';
import Icon from '@/components/ui/Icon';
import Tabs from '@/components/ui/tabs/Tabs';
import Tab from '@/components/ui/tabs/Tab';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    components: { Icon, Page, Tab, Tabs },

    mixins: [AuthMixin, InfiniteScrollMixin, NotifyMixin],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            STATUS_GROUP_RESIDENT_VALIDATION,
            ITEMS_LIMIT: 20,
            CREATE_ITEM_NOTIFICATION_ID: 'SREQ/create_service_request',
            UPDATE_ITEM_NOTIFICATION_ID: 'SREQ/update_service_request',

            statusOptions: [
                {
                    key: STATUS_GROUP_OPENED,
                    value: this.t('sreq.list.status_groups.opened'),
                    title: 'open requests',
                },
                {
                    key: STATUS_GROUP_RESIDENT_VALIDATION,
                    value: this.t('sreq.list.status_groups.to_be_approved'),
                    title: 'pending requests',
                    schema: SCHEMA_STATUS.ADVANCED,
                },
                {
                    key: STATUS_GROUP_RESOLVED,
                    value: this.t('sreq.list.status_groups.resolved'),
                    title: 'resolved requests',
                },
            ],

            status: STATUS_GROUP_OPENED,
            showAutocompleteInfobox: true,
            loading: false,
            schemaStatus: SCHEMA_STATUS.BASIC,
        };
    },

    computed: {
        schemaOptions() {
            return this.schemaStatus !== SCHEMA_STATUS.BASIC
                ? this.statusOptions.filter(option => !option.schema || option.schema === this.schemaStatus)
                : this.statusOptions.filter(option => !option.schema);
        },

        selectedTabTitle() {
            return this.schemaOptions.find(({ key }) => key === this.status)?.title || '';
        },

        columns() {
            const _columns = [
                {
                    name: 'Category',
                    value: value => this.getCategoryName(value),
                    rowClass: 'service-request-list__category',
                },
                {
                    name: 'Appliance',
                    value: value => value?.data?.appliance?.name,
                },
                {
                    name: 'Description',
                    value: value => value?.data?.description,
                },
                {
                    name: 'Status',
                    value: value => value?.metadata?.currentState?.name,
                },
            ];

            return [
                ..._columns,
                this.selectedTabTitle !== 'resolved requests'
                    ? {
                        name: 'Creation Date',
                        value: value => dateFormatter(value?.createdAt),
                    }
                    : {
                        name: 'Closing date',
                        value: value => dateFormatter(value?.metadata.stateChangedAt),
                    },
            ];
        },
    },

    watch: {
        currentProfile() {
            this.loadInitial();
        },

        status() {
            this.loadInitial();
        },
    },

    mounted() {
        this.loading = true;
        this.$sreqDataProvider
            .getSchema('serviceRequests')
            .then(response => {
                this.schemaStatus = response.statusSchema;
            })
            .catch(error => {
                this.notifyError(error.message);
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        getCategory(serviceRequest) {
            const customCategory = serviceRequest.metadata.customCategory;

            const buildCategoryPath = (path, categoryBranch) => {
                if (!categoryBranch) {
                    return customCategory ? [...path, { id: CATEGORY_CUSTOM, name: 'Custom Category' }] : path;
                }

                return buildCategoryPath([categoryBranch, ...path], categoryBranch.parent);
            };

            const categoryPath = buildCategoryPath([], serviceRequest.metadata.category);

            return categoryPath;
        },

        getCategoryName(serviceRequest) {
            const categoryPath = this.getCategory(serviceRequest);

            return categoryPath.map(({ name }) => name).join(' - ');
        },

        loadItems(options = {}) {
            return this.$sreqDataProvider.getList('serviceRequests', { status: this.status, ...options });
        },

        handleStatusUpdate(activeTab) {
            this.status = this.schemaOptions[activeTab].key;
        },

        closeAutocompleteInfobox() {
            this.showAutocompleteInfobox = false;
        },

        // prettier-ignore
        processAdditionalNotifications(notification) {
            switch (notification.channel) {
            case 'CHAT/update_chats_stat':
                for (const updateInfo of notification.body) {
                    const targetIndex = this.items.findIndex(item => item.value.metadata.chat.id === updateInfo.chatId);
                    if (targetIndex >= 0) {
                        this.items[targetIndex].value.metadata.chat.stat = updateInfo.stat;
                        this.items = [...this.items];
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.tabs {
    font-size: 15px;
}

.requests__empty-icon {
    @apply mb-8;
    width: 286px;
    height: 270px;
}

.infobox {
    @apply bg-gray-100 border-gray-150;
}

.list__header {
    @apply flex font-ffdin font-bold text-2xs mb-6;

    text-transform: uppercase;
}

.list__row {
    @apply flex font-sofia font-normal text-md items-center py-2;

    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.list__row:hover {
    @apply bg-gray-150;

    border-radius: 4px;
}

.list__cell {
    @apply w-1/4 mx-2;
}

.service-request-list__category {
    border-left: 3px solid theme('colors.purple.500');
    padding-left: 10px;
}

.service-request-list__add-button {
    @apply h-6 flex items-center;

    border-radius: 4px;

    span {
        margin-top: 2px;
    }
}
</style>
